@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&display=swap');
@font-face {
  font-family: "Pretendard";
  font-weight: 900;
  src: url('assets/fonts/prentendard/static/woff/Pretendard-Black.woff') format('woff');
}
@font-face {
  font-family: "Pretendard";
  font-weight: 800;
  src: url('assets/fonts/prentendard/static/woff/Pretendard-ExtraBold.woff') format('woff');
}
@font-face {
  font-family: "Pretendard";
  font-weight: 700;
  src: url('assets/fonts/prentendard/static/woff/Pretendard-Bold.woff') format('woff');
}
@font-face {
  font-family: "Pretendard";
  font-weight: 600;
  src: url('assets/fonts/prentendard/static/woff/Pretendard-SemiBold.woff') format('woff');
}
@font-face {
  font-family: "Pretendard";
  font-weight: 500;
  src: url('assets/fonts/prentendard/static/woff/Pretendard-Medium.woff') format('woff');
}
@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  src: url('assets/fonts/prentendard/static/woff/Pretendard-Regular.woff') format('woff');
}
@font-face {
  font-family: "Pretendard";
  font-weight: 300;
  src: url('assets/fonts/prentendard/static/woff/Pretendard-Light.woff') format('woff');
}
@font-face {
  font-family: "Pretendard";
  font-weight: 200;
  src: url('assets/fonts/prentendard/static/woff/Pretendard-ExtraLight.woff') format('woff');
}
@font-face {
  font-family: "Pretendard";
  font-weight: 100;
  src: url('assets/fonts/prentendard/static/woff/Pretendard-Thin.woff') format('woff');
}

body {
  margin: 0;
  font-family: "Pretendard Variable", "Pretendard", "-apple-system", "BlinkMacSystemFont", system-ui, "Roboto", "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 overflow-x: hidden;
 /* overflow-y: auto !important; */

padding-bottom: 0;
  padding-right: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  white-space: pre-line;
  justify-content: center;
  align-items:  center;
}
