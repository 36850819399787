.logo {
  background-image: url('../assets/images/logo_bk.png');
}

@media screen and (max-width: 1024px) {
  .mdDown {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .mdUp {
    display: none;
  }
}